import _Format from "../../Format/Format.js";
var exports = {};
const Format = _Format;
const monthnameDay = new Format({
  /* prettier-ignore */
  //                                $1             $2
  template: "^(?:(?:_DAYNAME_),? )?(_MONTHNAME_)? (_DAY_)(?:_ORDINAL_)?$",
  units: ["month", "day"]
});
exports = monthnameDay;
export default exports;