var exports = {};
const unitShortcuts = {
  y: "year",
  M: "month",
  d: "day",
  w: "week",
  h: "hour",
  m: "minute",
  s: "second",
  ms: "millisecond"
};
exports = unitShortcuts;
export default exports;