import _Format from "../../Format/Format.js";
var exports = {};
const Format = _Format;
const dayMonthnameYear = new Format({
  /* prettier-ignore */
  //                                $1                   $2    $3              $4
  template: "^(?:(?:_DAYNAME_),? )?(_DAY_)(?:_ORDINAL_)?([ -])(_MONTHNAME_)\\2(_YEAR_)$",
  units: ["day", null, "month", "year"]
});
exports = dayMonthnameYear;
export default exports;