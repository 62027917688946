import _Format from "../../Format/Format.js";
var exports = {};
const Format = _Format;
const dayMonth = new Format({
  /* prettier-ignore */
  //           $1            $2
  template: "^(_DAY_)[\\/. ](_MONTH_)$",
  units: ["day", "month"]
});
exports = dayMonth;
export default exports;