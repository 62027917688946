import _Parser from "./src/Parser/Parser.js";
import _Format from "./src/Format/Format.js";
import _LocaleHelper from "./src/LocaleHelper/LocaleHelper.js";
import _atSeconds from "./src/formats/atSeconds/atSeconds.js";
import _microsoftJson from "./src/formats/microsoftJson/microsoftJson.js";
import _ago from "./src/formats/ago/ago.js";
import _chinese from "./src/formats/chinese/chinese.js";
import _dayMonth from "./src/formats/dayMonth/dayMonth.js";
import _dayMonthname from "./src/formats/dayMonthname/dayMonthname.js";
import _dayMonthnameYear from "./src/formats/dayMonthnameYear/dayMonthnameYear.js";
import _dayMonthYear from "./src/formats/dayMonthYear/dayMonthYear.js";
import _defaultLocale from "./src/data/defaultLocale.js";
import _monthDay from "./src/formats/monthDay/monthDay.js";
import _monthDayYear from "./src/formats/monthDayYear/monthDayYear.js";
import _monthnameDay from "./src/formats/monthnameDay/monthnameDay.js";
import _monthnameDayYear from "./src/formats/monthnameDayYear/monthnameDayYear.js";
import _time12Hours from "./src/formats/time12Hours/time12Hours.js";
import _time24Hours from "./src/formats/time24Hours/time24Hours.js";
import _today from "./src/formats/today/today.js";
import _twitter from "./src/formats/twitter/twitter.js";
import _yearMonthDay from "./src/formats/yearMonthDay/yearMonthDay.js";
var exports = {};
// import our main modules
const Parser = _Parser;
const Format = _Format;
const LocaleHelper = _LocaleHelper;
// import our formats
const atSeconds = _atSeconds;
const microsoftJson = _microsoftJson;
const ago = _ago;
const chinese = _chinese;
const dayMonth = _dayMonth;
const dayMonthname = _dayMonthname;
const dayMonthnameYear = _dayMonthnameYear;
const dayMonthYear = _dayMonthYear;
const defaultLocale = _defaultLocale;
const monthDay = _monthDay;
const monthDayYear = _monthDayYear;
const monthnameDay = _monthnameDay;
const monthnameDayYear = _monthnameDayYear;
const time12Hours = _time12Hours;
const time24Hours = _time24Hours;
const today = _today;
const twitter = _twitter;
const yearMonthDay = _yearMonthDay;

// create a default parser instance and register all the default formats
const parser = new Parser();
parser
// all formats can have time strings at the end
.addFormats([time24Hours, time12Hours,
// from most unambiguous and popular to least
yearMonthDay, dayMonthnameYear, monthnameDayYear, monthDayYear, dayMonthYear, chinese, twitter, today, ago, monthnameDay, dayMonthname, monthDay, dayMonth, atSeconds, microsoftJson]);

// make it easy to consume our other main modules and functions
parser.Parser = Parser;
parser.Format = Format;
parser.LocaleHelper = LocaleHelper;
parser.defaultLocale = defaultLocale;

// create functions on Date
parser.fromString = Date.fromString = parser.exportAsFunction();
parser.fromAny = Date.fromAny = parser.exportAsFunctionAny();
if (typeof window !== "undefined") {
  /* istanbul ignore next */
  window.anyDateParser = parser;
}

// export our default parser
exports = parser;
export default exports;