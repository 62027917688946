import _Format from "../../Format/Format.js";
var exports = {};
const Format = _Format;
const monthDay = new Format({
  /* prettier-ignore */
  //           $1                 $2
  template: "^(_MONTH_)(?:[\\/-])(_DAY_)$",
  units: ["month", "day"]
});
exports = monthDay;
export default exports;