import _Format from "../../Format/Format.js";
var exports = {};
const Format = _Format;
const dayMonthname = new Format({
  /* prettier-ignore */
  //           $1                       $2
  template: "^(_DAY_)(?:_ORDINAL_)?[ -](_MONTHNAME_)$",
  units: ["day", "month"]
});
exports = dayMonthname;
export default exports;