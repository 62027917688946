var exports = {};
const startCodes = {
  arab: 1632,
  arabext: 1776,
  bali: 6992,
  beng: 2534,
  deva: 2406,
  fullwide: 65296,
  gujr: 2790,
  khmr: 6112,
  knda: 3302,
  laoo: 3792,
  limb: 6470,
  mlym: 3430,
  mong: 6160,
  mymr: 4160,
  orya: 2918,
  tamldec: 3046,
  telu: 3174,
  thai: 3664,
  tibt: 3872
};

// full-width numbers, hanidec numbers, latin numbers (\d)
const chineseGroup = "[\uFF10\uFF11\uFF12\uFF13\uFF14\uFF15\uFF16\uFF17\uFF18\uFF19\u3007\u4E00\u4E8C\u4E09\u56DB\u4E94\u516D\u4E03\u516B\u4E5D\\d]";
const defaultLookup = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  "\uFF10": 0,
  "\uFF11": 1,
  "\uFF12": 2,
  "\uFF13": 3,
  "\uFF14": 4,
  "\uFF15": 5,
  "\uFF16": 6,
  "\uFF17": 7,
  "\uFF18": 8,
  "\uFF19": 9,
  〇: 0,
  一: 1,
  二: 2,
  三: 3,
  四: 4,
  五: 5,
  六: 6,
  七: 7,
  八: 8,
  九: 9
};
const cache = {};
function buildDigits(nsName) {
  if (cache[nsName]) {
    return cache[nsName];
  }
  if (nsName === "fullwide" || nsName === "hanidec") {
    return {
      group: chineseGroup,
      lookup: {
        ...defaultLookup
      }
    };
  }
  const startCode = startCodes[nsName];
  /* istanbul ignore next */
  if (!startCode) {
    // unknown numbering system; treat like latn
    return {
      group: "\\d",
      lookup: {
        ...defaultLookup
      }
    };
  }
  const start = String.fromCharCode(startCode);
  const end = String.fromCharCode(startCode + 9);
  const lookup = {};
  for (let i = 0; i < 10; i++) {
    lookup[String.fromCharCode(startCode + i)] = i;
  }
  // console.log({ nsName, start, end, lookup });
  cache[nsName] = {
    group: `[${start}-${end}]`,
    lookup
  };
  return cache[nsName];
}
exports = {
  chineseGroup,
  defaultLookup,
  startCodes,
  buildDigits
};
export default exports;